import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Location from "../components/location"


const TestimonialPage = () => {
  const data = useStaticQuery(graphql`
    query {
      testimonials: allSanityTestimonial {
        nodes {
          customer
          quote
        }
      }
      headerImage: file(relativePath: { eq: "homepage_panel_03.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const {testimonials, headerImage} = data;

  return (
    <Layout headerImage={headerImage}>
      <SEO title="Customer Feedback for Willow Branch Flowers" />
      <section className="bg-textured-content py-12 border-b border-gray-400">
        <div className="md:flex container mx-auto">
          <div className="md:w-1/3 pr-12 px-2 md:px-0">
            <h1 className="text-3xl font-serif font-bold text-green-800 mb-2">Client Testimonials</h1>

            <Location subPage />

          </div>
          <div className="md:w-2/3 md:pl-4 flex flex-wrap">
            {testimonials.nodes.map((item,idx)=>{
              return (
              <div key={idx} className="px-2 md:px-0 mb-8">
                  <blockquote className="font-serif leading-loose italic">
                    <p>"{item.quote}"</p>
                    <cite className="font-sans not-italic">- {item.customer}</cite>
                  </blockquote>
              </div>
              )
            })}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default TestimonialPage
